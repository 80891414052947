import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import EthereumWalletConnectProvider, {
  EthereumProvider as WalletConnectEthereumProvider
} from '@walletconnect/ethereum-provider';
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import { EthereumNetwork } from '@manifoldxyz/frontend-provider-types';
import {
  AbstractProvider,
  ALLOWED_WALLET_CONNECT_WALLET_IDS,
  InjectedProvider
} from '@/common/constants';
import WalletMixin, { BrowserWalletConfig, BrowserWallets } from '@/mixins/wallet';

/**
 * This is a list of non-brwoser wallet connection methods
 */
enum NonBrowserConnectMethods {
  WALLET_CONNECT = 'walletConnect',
  COINBASE = 'coinbase'
}

export default class MultiWalletMixin extends mixins(WalletMixin) {
  injectedWallet = false;

  async connectBrowserWallet(walletConfig: BrowserWalletConfig): Promise<void> {
    if (walletConfig.name === 'Coinbase Wallet') {
      await this.connectCoinbaseWallet();
      return;
    }

    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithEthereumProvider(false, walletConfig);
      localStorage.setItem('connectMethod', walletConfig.name);
      this.injectedWallet = true;
    } catch {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  /**
   * Helper to establish wallet connect connection
   */
  async connectWalletConnect(): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithWalletConnect();
    } catch (error) {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  async connectCoinbaseWallet(): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithCoinbaseWallet();
    } catch (error) {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(true, true);
    }
  }

  async _automaticallyReconnect(): Promise<void> {
    this._automaticallyConnectMultiWallet();
  }

  async _automaticallyConnectMultiWallet(): Promise<void> {
    // Reconnect if
    // 1. autoReconnect is set
    // 2. we have a connected address in local storage
    if (this.autoReconnect && localStorage.getItem('connectedAddress')) {
      const connectMethod = localStorage.getItem('connectMethod');
      // make sure to reconnect using the correct connection method
      if (connectMethod === NonBrowserConnectMethods.WALLET_CONNECT) {
        await this._connectWithWalletConnect();
      } else if (
        connectMethod === BrowserWallets.CoinbaseWallet ||
        connectMethod === NonBrowserConnectMethods.COINBASE
      ) {
        await this._connectWithCoinbaseWallet();
      } else if (connectMethod) {
        const browserWalletProvider = this.getBrowserWalletProvider(
          connectMethod as BrowserWallets
        );
        if (browserWalletProvider) {
          await this._connectWithEthereumProvider(
            true,
            this.getBrowserWalletConfig(browserWalletProvider)
          );
          this.injectedWallet = true;
        }
      }
    }
  }

  async _connectWithWalletConnect(): Promise<void> {
    let chains = this.network;
    // If no networks, just force wallet connect to connect to mainnet
    if (chains.length === 0) {
      chains = [1];
    }

    const walletConnectProjectId =
      this.walletConnectProjectId || this.detectedApp?.walletConnectProjectId;

    if (!walletConnectProjectId)
      throw new Error('Wallet Connect Project ID needs to be defined to use WalletConnect');
    // if they did not provider a specific wallet provider, use the fallback
    const providerURIs = this._getProviderURIs();
    // create the separate WC provider and use it as the _signingProvider in EthereumProvider
    const optionalChains = this.optionalNetwork;
    const wcProviderConfig: EthereumProviderOptions = {
      projectId: walletConnectProjectId,
      rpcMap: providerURIs?.length ? this._getRpcMapping() : undefined,
      chains: chains as [EthereumNetwork, ...EthereumNetwork[]],
      optionalChains: optionalChains as [EthereumNetwork, ...EthereumNetwork[]],
      showQrModal: true,
      qrModalOptions: {
        explorerRecommendedWalletIds: ALLOWED_WALLET_CONNECT_WALLET_IDS,
        explorerExcludedWalletIds: 'ALL'
      }
    };
    const wcProvider = await WalletConnectEthereumProvider.init(wcProviderConfig);

    try {
      // if there is already a session, we already connected before, so we don't need to connect again
      if (!wcProvider.session) {
        await wcProvider.connect();
      }
      const web3Provider = new Web3Provider(wcProvider, 'any');
      this._setUpWalletConnectEventListeners(wcProvider, web3Provider);
      await EthereumProvider.setSigningProvider(web3Provider);
      localStorage.setItem('connectMethod', NonBrowserConnectMethods.WALLET_CONNECT);
      this.injectedWallet = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // Force disconnect if it errors (which happens if they just close modal and select nothing for WC)
      this._disconnect(true, true);
      this.isLoading = false;
      if (e.message !== 'User rejected the request.') {
        console.error(`Unknown WalletConnect error`, e);
      }
    }
  }

  _setUpWalletConnectEventListeners(
    wcProvider: EthereumWalletConnectProvider,
    web3Provider: Web3Provider
  ): void {
    // need to reset signing provider if user change accounts or networks
    wcProvider.on('accountsChanged', () => {
      EthereumProvider.setSigningProvider(web3Provider);
    });
    wcProvider.on('chainChanged', (_: string) => {
      EthereumProvider.setSigningProvider(web3Provider);
    });
    wcProvider.on('disconnect', () => {
      // Force disconnect (also disconnect provider)
      this._disconnect(false, true);
    });
    wcProvider.on('session_delete', () => {
      // Force disconnect (no need to disconnect provider)
      this._disconnect(false, true);
    });
  }

  async _connectWithCoinbaseWallet(): Promise<void> {
    // @dev: see if we have injected coinbase wallet
    const windowEthereum = window.ethereum;
    const injectedCoinbaseWallet: InjectedProvider | undefined = windowEthereum?.isCoinbaseWallet
      ? windowEthereum
      : windowEthereum?.providers?.find((p: { isCoinbaseWallet: boolean }) => !!p.isCoinbaseWallet);

    try {
      if (injectedCoinbaseWallet) {
        // Trigger connect for injected wallet
        await this._connectWithEthereumProvider(
          false,
          this.getBrowserWalletConfig(injectedCoinbaseWallet)
        );
        localStorage.setItem('connectMethod', BrowserWallets.CoinbaseWallet);
      } else {
        // Trigger coinbase connect
        const coinbaseSDK = new CoinbaseWalletSDK({
          appName: this.appName
        });
        const coinbaseProvider = coinbaseSDK.makeWeb3Provider();
        await coinbaseProvider.request({ method: 'eth_requestAccounts' });
        const web3Provider = new Web3Provider(
          coinbaseProvider as unknown as ExternalProvider,
          'any'
        );
        if (this.network.length === 1) {
          const currentNetwork = await web3Provider.getNetwork();
          if (currentNetwork.chainId !== this.network[0]) {
            await web3Provider.send('wallet_switchEthereumChain', [
              { chainId: `0x${this.network[0].toString(16)}` }
            ]);
            // NOTE: Force the network to be detected again by clearing the web3Provider cache
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            web3Provider._cache['detectNetwork'] = null as any;
          }
        }
        await EthereumProvider.setSigningProvider(web3Provider);
        localStorage.setItem('connectMethod', BrowserWallets.CoinbaseWallet);
        this.injectedWallet = false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.message !== 'User denied account authorization') {
        console.error(`Unknown Coinbase Wallet error`, e);
      }
      // Force disconnect (no need to disconnect provider), which happens if they just close modal and do nothing for coinbase wallet
      this._disconnect(true, true);
    }
  }

  /**
   * Overrides _disconnect found in all the mixins
   */
  _disconnect(skipProviderDisconnect?: boolean, force = false): void {
    this._disconnectMultiWallet(skipProviderDisconnect);
    this._disconnectBase(skipProviderDisconnect, force);
  }

  _disconnectMultiWallet(skipProviderDisconnect = false): void {
    if (this.walletConnected) {
      const connectMethod = localStorage.getItem('connectMethod');
      // remove local storage
      localStorage.removeItem('connectMethod');

      const signingProvider = EthereumProvider.provider(true) as AbstractProvider;

      // Handle bridged provider connections
      if (!skipProviderDisconnect && signingProvider) {
        if (connectMethod === NonBrowserConnectMethods.WALLET_CONNECT) {
          try {
            signingProvider.provider.disconnect();
          } catch (error) {
            // eat the error as it's not critical if this step goes awry
            console.warn(error);
          }
        } else if (
          connectMethod === BrowserWallets.CoinbaseWallet ||
          connectMethod === NonBrowserConnectMethods.COINBASE
        ) {
          try {
            signingProvider.provider.disconnect();
          } catch (error) {
            // eat the error as it's not critical if this step goes awry
            console.warn(error);
          }
        }
      }
    }
  }
}
